import axios from "../utils/request.js";

// 首页数据业务统计
export const homeStatistics = () => {
  return axios({
    method: "get",
    url: "/freight/website/homeStatistics",
  });
};

// 货源信息
export const websiteGoodList = (data) => {
  return axios({
    method: "post",
    url: "/freight/orderGoods/websiteGoodList",
    data,
  });
};

// 分页获取评价列表
export const evaluatePage = (data) => {
  return axios({
    method: "post",
    url: "/freight/orderEvaluate/websiteEvaluatePage",
    data: data,
  });
};

// 车辆管理列表接口
export const websiteVehicleList = (data) => {
  return axios({
    method: "post",
    url: "/freight/officialWebsiteData/websiteVehicleList",
    data
  });
};

// 投诉公示
export const complaintsPage = () => {
  return axios({
    method: "get",
    url: "/freight/msgComplaint/websiteComplaintsPage",
  });
}

// 保存咨询问题
export const complaints = (data) => {
  return axios({
    method: "post",
    url: "/freight/msgComplaint/saveMsgConsultingComplaints",
    data
  });
}
