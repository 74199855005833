import axios from "axios";
//带三方类库
import qs from "qs";


axios.defaults.baseURL = "https://web.dongche56.com"


/**
 * 设置超时时间和跨域是否允许携带凭证
 */
axios.defaults.timeout = 10000; //设置十秒

//axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded' //声明请求格式
axios.defaults.transformRequest = (data) => qs.stringify(data); //qs是第三方库，转换为x-www-form-urlencoded

/**
 * 客户端->[请求拦截器]->服务器端
 * config :发起请求的请求配置项
 */
axios.interceptors.request.use(
  (config) => {
    // config.headers['content-type'] = 'application/json; charset=UTF-8'
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * 设置响应拦截器
 * 服务器端返回信息->[响应拦截器]->客户端js获取到信息
 * response中包含属性：
 * data：相应数据,status:响应状态码,statusText：响应状态信息,headers：响应头,config：响应提供的配置信息,request
 */
axios.interceptors.response.use(
  (response) => {
    return response; //将主体内容返回  axios.get().then(result=>{拿到的就是响应主体})
  },
  (error) => {
    let { response } = error;
    // 如果有返回结果
    if (response) {
      switch (response.status) {
        //这里面根据公司需求进行写
        case 404:
          break;
      }
    } else {
      //服务器没有返回结果 分两种情况 断网  服务器崩了
      if (!window.navigator.onLine) {
        //断网处理：跳转到断网页面
        return;
      }
      return Promise.reject(error);
    }
  }
);

export default axios;
