import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs;//可以全局使用dayjs

// 引入amap 高德地图
import AmapVue from '@amap/amap-vue';
AmapVue.config.key = 'a1745b4c0f2f4f93bdb902e1106ac5a3';//秘钥
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
Vue.use(AmapVue);
// Vue.use(unit)
Vue.use(ElementUI)

Vue.config.productionTip = false

router.beforeEach((to,from,next) => {
  next()
  store.commit('setDefaultActive',to.name)
  //console.log(to.name,store.state.defaultActive,'----')
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
