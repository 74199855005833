import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Main from '@/components/main.vue'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 注：官方vue-router@3.0及以上新版本路由默认回调返回的都是promise，原先就版本的路由回调将废弃！！！！



Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/', 
    name: 'main',
    component:Main,
    children:[
      { path: 'sourceInformation', name: 'sourceInformation', component: () => import('../views/sourceInformation.vue') },
      { path: 'vehicleInformation', name: 'vehicleInformation', component: () => import('../views/vehicleInformation.vue') },
      { path: 'complaintPublicity', name: 'complaintPublicity', component: () => import('../views/complaintPublicity.vue') },
      { path: 'evaluationPublicity', name: 'evaluationPublicity', component: () => import('../views/evaluationPublicity.vue') },
      { path: 'customerServiceContact', name: 'customerServiceContact', component: () => import('../views/customerServiceContact.vue') },
      { path: 'contactUs', name: 'contactUs', component: () => import('../views/contactUs.vue') },
      { path: 'userGuide', name: 'userGuide', component: () => import('../views/userGuide.vue') },
    ]
  },
  {
    path: '*', 
    name: 'error', 
    component: () => import('../views/errorHome.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
