<template>
  <div class="home" @mousewheel="rollScroll($event)">
    <!-- 上下走马灯 -->
    <el-carousel
      @mousewheel="rollScroll($event)"
      :height="carouselHeight"
      ref="carousel"
      :autoplay="false"
      trigger="click"
      direction="vertical"
    >
      <el-carousel-item style="position: relative">
        <!-- 左右走马灯 -->
        <el-container>
          <el-header height="90"
            ><Header :styleObject="styleObject"
          /></el-header>
          <el-carousel
            :interval="3000"
            :height="carouselHeight2"
            indicator-position="none"
          >
            <!-- <el-carousel-item v-for="(item,index) in carouselList" :key="index">
              <img style="width:100%;height:100%" :src="item" alt="">
            </el-carousel-item>
          </el-carousel> -->
            <el-carousel-item>
              <div class="backgroundImg">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/1.png"
                  alt=""
                />
                <div class="title_pos">
                  <p class="p1">高效的运输管理系统</p>
                  <p class="p2">安全、可靠、让您发货无忧</p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="backgroundImg">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/2.png"
                  alt=""
                />
                <div class="title_pos">
                  <p class="p1">多种产品形态，多种终端覆盖</p>
                  <p class="p2">让每一次操作更加便捷</p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="backgroundImg">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/3.png"
                  alt=""
                />
                <div class="title_pos">
                  <p class="p1">强大的运力组织及调度能力</p>
                  <p class="p2">让每一次运输更效、快捷</p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="backgroundImg">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/2.png"
                  alt=""
                />
                <div class="title_pos">
                  <p class="p1">灵活的对账结算体系</p>
                  <p class="p2">支持实时、账期等多种结算方式</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-container>
        <div class="carousel_centent">
          <el-row type="flex" justify="center">
            <el-col :span="20">
              <div class="carousel_centent_box">
                <div class="carousel_centent_list border_right_none">
                  <img
                    style="width: 60px; height: 60px"
                    src="@/assets/icon/1.png"
                    alt=""
                  />
                  <div class="carousel_centent_li">
                    <span class="carousel_centent_li_title">平台货主</span>
                    <span class="carousel_centent_li_count">{{
                      cargoOwnerNum
                    }}</span>
                  </div>
                </div>
                <div class="carousel_centent_list border_right_none">
                  <img
                    style="width: 60px; height: 60px"
                    src="@/assets/icon/2.png"
                    alt=""
                  />
                  <div class="carousel_centent_li">
                    <span class="carousel_centent_li_title">平台司机</span>
                    <span class="carousel_centent_li_count">{{
                      driverNum
                    }}</span>
                  </div>
                </div>
                <div class="carousel_centent_list border_right_none">
                  <img
                    style="width: 60px; height: 60px"
                    src="@/assets/icon/3.png"
                    alt=""
                  />
                  <div class="carousel_centent_li">
                    <span class="carousel_centent_li_title">平台车辆</span>
                    <span class="carousel_centent_li_count">{{
                      vehicleNum
                    }}</span>
                  </div>
                </div>
                <div class="carousel_centent_list border_right_none">
                  <img
                    style="width: 60px; height: 60px"
                    src="@/assets/icon/4.png"
                    alt=""
                  />
                  <div class="carousel_centent_li">
                    <span class="carousel_centent_li_title">本月运单</span>
                    <span class="carousel_centent_li_count">{{
                      orderNum
                    }}</span>
                  </div>
                </div>
                <div class="carousel_centent_list">
                  <img
                    style="width: 60px; height: 60px"
                    src="@/assets/icon/6.png"
                    alt=""
                  />
                  <div class="carousel_centent_li">
                    <span class="carousel_centent_li_title">本月运量</span>
                    <span class="carousel_centent_li_count">{{
                      realGoodWeight
                    }}</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <el-container>
          <div class="box_background">
            <div class="footer_box">
              <el-row type="flex" justify="center" style="height: 100%">
                <el-col :span="20" style="height: 100%">
                  <div class="footer_centent">
                    <div class="footer_box_left">
                      <div class="footer_box_left_box">
                        <!-- <img src="https://www.shantuai.com/static/img/official.2184518.jpg" alt="">
                        <p class="footer_box_left_box_p1">微信公众号</p>
                        <p class="footer_box_left_box_p2">扫码关注</p> -->
                      </div>
                      <div
                        class="footer_box_left_box footer_box_left_box_style"
                      >
                        <!-- <img src="https://www.shantuai.com/static/img/official.2184518.jpg" alt="">
                        <p class="footer_box_left_box_p1">用户APP</p>
                        <p class="footer_box_left_box_p2">扫码关注</p> -->
                      </div>
                    </div>
                    <div class="footer_box_right">
                      <!-- <img src="https://www.shantuai.com/static/img/3333.17fc836.png" alt="">
                      <img src="https://www.shantuai.com/static/img/3333.17fc836.png" alt="">
                      <img src="https://www.shantuai.com/static/img/3333.17fc836.png" alt="">
                      <img src="https://www.shantuai.com/static/img/3333.17fc836.png" alt=""> -->
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-footer><Footer :styleTop="styleTop" /></el-footer>
          </div>
        </el-container>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Main from "@/components/main.vue";
import Footer from "@/components/footer.vue";
import { homeStatistics } from "../api/index.js";

export default {
  name: "HomeView",
  components: {
    Header,
    Main,
    Footer,
  },
  data() {
    return {
      styleObject: "#515a6e", //样式
      styleTop: "20px", //样式
      // 动态高度
      carouselHeight: document.documentElement.clientHeight + "px",
      carouselHeight2: document.documentElement.clientHeight - 90 + "px",
      carouselList: [
        "https://www.shantuai.com/static/img/1.3efa2a0.png",
        "https://www.shantuai.com/static/img/2.2e4e861.png",
        "https://www.shantuai.com/static/img/5.f4063f7.png",
        "https://www.shantuai.com/static/img/2.2e4e861.png",
      ],
      realGoodWeight: "", //本月运量
      orderNum: "", //本月运单
      vehicleNum: "", //平台车辆
      driverNum: "", //平台司机
      cargoOwnerNum: "", //平台货主
    };
  },
  created() {
    this.onload();
  },
  methods: {
    onload() {
      homeStatistics().then((res) => {
        // console.log("首页业务数据统计=>", res.data.result);
        this.realGoodWeight = res.data.result.realGoodWeight;
        this.orderNum = res.data.result.orderNum;
        this.vehicleNum = res.data.result.vehicleNum;
        this.driverNum = res.data.result.driverNum;
        this.cargoOwnerNum = res.data.result.cargoOwnerNum;
      });
    },
    rollScroll(event) {
      let _that = this;
      // chrome、ie使用的wheelDelta，火狐使用detail
      let scrollVal = event.wheelDelta || event.detail;
      // 节流
      if (!_that.timeOut) {
        _that.timeOut = setTimeout(() => {
          _that.timeOut = null;
          scrollVal > 0
            ? _that.$refs.carousel.prev()
            : _that.$refs.carousel.next();
        }, 300);
      } else {
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
}
.carousel_centent {
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0;
  bottom: 0px;
  z-index: 2;
  .carousel_centent_box {
    height: 140px;
    background: rgba(0, 10, 29, 0.7);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .carousel_centent_list {
      width: 24%;
      height: 100%;
      border: 1px solid #3e4551;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 60px;
        color: #f85e22;
      }
      .carousel_centent_li {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .carousel_centent_li_title {
          color: #d3d3d4;
          font-size: 16px;
        }
        .carousel_centent_li_count {
          color: #fffefe;
          font-size: 34px;
          font-weight: 700;
          margin-top: 20px;
        }
      }
    }
    .border_right_none {
      border-right: none;
    }
  }
}
.box_background {
  width: 100%;
  height: 100%;
  background-image: url("https://www.shantuai.com/static/img/bg.77f7dc0.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.footer_box {
  width: 100%;
  height: 67vh;
  // background-image:url('https://www.shantuai.com/static/img/bg.77f7dc0.png');
  position: relative;
  .footer_centent {
    width: 100%;
    position: absolute;
    top: 30%;
    display: flex;
    .footer_box_left {
      display: flex;
      .footer_box_left_box {
        text-align: center;
        img {
          width: 128px;
          height: 128px;
        }
        .footer_box_left_box_p1 {
          color: #fff;
          font-size: 18px;
          margin-top: 20px;
          margin-bottom: 12px;
        }
        .footer_box_left_box_p2 {
          height: 32px;
          width: 130px;
          border: 1px solid #fff;
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 32px;
        }
      }
      .footer_box_left_box_style {
        margin-left: 200px;
        margin-right: 100px;
      }
    }
    .footer_box_right {
      img {
        margin-right: 20px;
        width: 180px;
        height: 200px;
      }
    }
  }
}
.backgroundImg {
  width: 100%;
  height: 100%;
  position: relative;
  .title_pos {
    position: absolute;
    left: 100px;
    top: 50%;
    margin-top: -50px;
    // background: red;
    .p1 {
      font-size: 75px;
      font-weight: 600;
      color: #fff;
    }
    .p2 {
      font-size: 40px;
      color: #fff;
    }
  }
}
</style>
