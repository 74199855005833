<template>
  <div class="home">
    <el-container>
      <el-header height='90'><Header  :styleObject="styleObject" /></el-header>
      <el-main><router-view /></el-main>
      <el-footer><Footer :styleTop="styleTop" /></el-footer>
    </el-container>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'

export default {
  name: 'Main',
  components:{
    Header,
    Footer
  },
  data () {
    return {
        styleObject:'#fff',
        styleTop:'50px'
    }
  }
}
</script>
<style lang="less" scoped>
.home{
  width: 100%;
  height: 100%;
}
</style>
