<template>
  <div>
    <el-row type="flex" :style="{background:styleObject}" class="header_background" justify="center">
        <el-col :span="22">
            <div class="header header_centent">
                <img v-if="activeIndex=='home'" src="../assets/logo.png" alt="">
                <router-link to="/" v-else>
                    <img src="../assets/logo.png" alt="">
                </router-link>
                <div>
                    <el-menu :default-active="activeIndex" router :background-color="styleObject" active-text-color="#31a084" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                        <el-menu-item index="sourceInformation">货源信息</el-menu-item>
                        <el-menu-item index="vehicleInformation">车源信息</el-menu-item>
                        <el-menu-item index="complaintPublicity">评价公示</el-menu-item>
                        <el-menu-item index="evaluationPublicity">投诉公示</el-menu-item>
                        <el-submenu index="5">
                            <template slot="title">联系客服</template>
                            <el-menu-item index="customerServiceContact">投诉建议</el-menu-item>
                            <el-menu-item index="contactUs">联系我们</el-menu-item>
                        </el-submenu>
                    </el-menu>
                </div>
            </div>
        </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: '',
  props:{
    styleObject:{
        type:String,
        default:'#515a6e'
    }
  },
  data () {
    return {
        activeIndex:'',
    }
  },
  methods:{
    handleSelect(key, keyPath) {
        this.$router.push(key)
        //console.log(key, keyPath);
      }
  },
  created(){
    this.activeIndex=this.$store.state.defaultActive || ''
    //console.log(this.activeIndex,'00')
  },
  watch:{
    styleObject:function(val){
        //console.log(val,'v')
    }
  }
}
</script>

<style lang="less" scoped>
.header_background{
    background: #515a6e;
}
.header{
    height: 90px;
}
.header_centent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px !important;
    img{
        height: 44px;
    }
}
</style>
